import './BurgerMenuButton.css';

import * as React from 'react';
import PropTypes from 'prop-types';

function BurgerMenuButton(props) {
  const {
    openBurgerMenuDropdown,
  } = props;
  return (
    <div id="BurgerMenuButton">
      <div
        id="burger-menu-wrapper"
        className="unauthenticated-bar"
        role="button"
        tabIndex="0"
        onClick={openBurgerMenuDropdown}
        onKeyPress={openBurgerMenuDropdown}
      >
        <div><i className="fas fa-bars burger-menu" id="burger-menu-icon" /></div>
      </div>
    </div>
  );
}

BurgerMenuButton.propTypes = {
  openBurgerMenuDropdown: PropTypes.func.isRequired,
};

export default BurgerMenuButton;
