import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {CancelToken} from 'axios';
import axios from '../../../utils/axios';
import config from '../../../utils/config.json';
import './Status.css';

let source;

function getUserInfo() {
  return new Promise((resolve, reject) => {
    axios.get(`${config.apiUrl}/userinfo`, {cancelToken: source.token})
      .then(res => {
        if (res.status !== 200) {
          return reject(new Error("Error finding user info"));
        }
        return resolve(res.data);
      })
      .catch((error) => {
        return reject(error);
      })
  })
}

class Status extends Component {

  constructor(props) {
    super(props);

    this.state = {
      profile: {},
      nickname: null,
      points: 0,
      rank2018: 'unknown',
      rank2019: 'unknown',
      rank2020: 'unknown',
      rank2021: 'unknown',
      rank2022: 'unknown',
      position: 'unknown',
      commendations2018: [],
      commendations2019: [],
      commendations2020: []
    };
    source = CancelToken.source();
  }

  componentDidMount() {

    /*
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        this.setState({ profile });
      });
    } else {
      this.setState({ profile: userProfile });
    }*/

    getUserInfo()
      .then((result) => {
        this.setState({
          nickname: results.username,
          points:result.points,
          rank2018:result.rank2018,
          rank2019:result.rank2019,
          rank2020:result.rank2020,
          rank2021:result.rank2021,
          rank2022:result.rank2022,
          position:result.position,
          commendations2018:result.commendations2018,
          commendations2019:result.commendations2019,
          commendations2020:result.commendations2020
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted component"){
          console.log(error);          
        }
      });
  }

  componentWillUnmount() {
    if (source) {
      source.cancel("Unmounted component");
    }
  }

  render() {
    return (
      <Container fluid className="status">
        <Row>
          <Col xs={6} md={{span: 4, offset: 2}} className="username">
            {this.state.nickname}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={{span: 4, offset: 2}} className="points">
            Points: {this.state.points}
          </Col>
          <Col xs={4} className="position">
            Leaderboard: {this.state.position}
          </Col>
        </Row>

        <Row>
          <Col xs={3} md={{span: 3, offset: 2}} className="section-title">
            Legacy
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={{span: 4, offset: 2}} className="rank">
            2020 Rank: {this.state.rank2020}
          </Col>
          <Col xs={4} className="points">
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{span: 8, offset: 2}}>
            <Row>
              <Col xs={3}>
                {(this.state.commendations2020 && this.state.commendations2020.includes(1)) ? 
                  <p className="ach-earned" data-tip data-for="ciphered">Ciphered</p>
                      :
                  <p className="ach-unearned" data-tip data-for="ciphered">Ciphered</p>
                }
                <ReactTooltip id="ciphered"><span>Complete all cipher decryption challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2020 && this.state.commendations2020.includes(2)) ? 
                  <p className="ach-earned" data-tip data-for="apiBuster">API Buster</p>
                      :
                  <p className="ach-unearned" data-tip data-for="apiBuster">API Buster</p>
                }
                <ReactTooltip id="apiBuster"><span>Complete all API hacking challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2020 && this.state.commendations2020.includes(3)) ? 
                  <p className="ach-earned" data-tip data-for="packetWhisperer">Packet Whisperer</p>
                      :
                  <p className="ach-unearned" data-tip data-for="packetWhisperer">Packet Whisperer</p>
                }
                <ReactTooltip id="packetWhisperer"><span>Complete all packet injection challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2020 && this.state.commendations2020.includes(4)) ? 
                  <p className="ach-earned" data-tip data-for="done">Done!</p>
                      :
                  <p className="ach-unearned" data-tip data-for="done">Done!</p>
                }
                <ReactTooltip id="done"><span>Complete EVERY challenge</span></ReactTooltip>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={{span: 4, offset: 2}} className="rank">
            2019 Rank: {this.state.rank2019}
          </Col>
          <Col xs={4} className="points">
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{span: 8, offset: 2}}>
            <Row>
              <Col xs={3}>
                {(this.state.commendations2019 && this.state.commendations2019.includes(1)) ? 
                  <p className="ach-earned" data-tip data-for="driversEd">Driver's Ed</p>
                      :
                  <p className="ach-unearned" data-tip data-for="driversEd">Driver's Ed</p>
                }
                <ReactTooltip id="driversEd"><span>Complete recruit training</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2019 && this.state.commendations2019.includes(2)) ? 
                  <p className="ach-earned" data-tip data-for="traitor">Traitor</p>
                      :
                  <p className="ach-unearned" data-tip data-for="traitor">Traitor</p>
                }
                <ReactTooltip id="traitor"><span>Unlock the MorningGlory botnet faction</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2019 && this.state.commendations2019.includes(3)) ? 
                  <p className="ach-earned" data-tip data-for="haveIBeenPwned">HaveIBeenPwned?</p>
                      :
                  <p className="ach-unearned" data-tip data-for="haveIBeenPwned">HaveIBeenPwned?</p>
                }
                <ReactTooltip id="haveIBeenPwned"><span>Complete all password cracking challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2019 && this.state.commendations2019.includes(4)) ? 
                  <p className="ach-earned" data-tip data-for="cryptonomicon">Cryptonomicon</p>
                      :
                  <p className="ach-unearned" data-tip data-for="cryptonomicon">Cryptonomicon</p>
                }
                <ReactTooltip id="cryptonomicon"><span>Complete all cipher decryption challenges</span></ReactTooltip>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                {(this.state.commendations2019 && this.state.commendations2019.includes(5)) ? 
                  <p className="ach-earned" data-tip data-for="stegawhat">Stegawhat?</p>
                      :
                  <p className="ach-unearned" data-tip data-for="stegawhat">Stegawhat?</p>
                }
                <ReactTooltip id="stegawhat"><span>Complete all steganography challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2019 && this.state.commendations2019.includes(6)) ? 
                  <p className="ach-earned" data-tip data-for="botking">Bot King</p>
                      :
                  <p className="ach-unearned" data-tip data-for="botking">Bot King</p>
                }
                <ReactTooltip id="botking"><span>Level your botnet to lvl 50</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2019 && this.state.commendations2019.includes(7)) ? 
                  <p className="ach-earned" data-tip data-for="scriptkiddie">Script Kiddie</p>
                      :
                  <p className="ach-unearned" data-tip data-for="scriptkiddie">Script Kiddie</p>
                }
                <ReactTooltip id="scriptkiddie"><span>Complete all hacking challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2019 && this.state.commendations2019.includes(8)) ? 
                  <p className="ach-earned" data-tip data-for="prefect">Prefect</p>
                      :
                  <p className="ach-unearned" data-tip data-for="prefect">Prefect</p>
                }
                <ReactTooltip id="prefect"><span>Complete EVERY challenge</span></ReactTooltip>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={{span: 4, offset: 2}} className="rank">
            2018 Rank: {this.state.rank2018}
          </Col>
          <Col xs={4} className="points">
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{span: 8, offset: 2}}>
            <Row>
              <Col xs={3}>
                {(this.state.commendations2018 && this.state.commendations2018.includes(1)) ? 
                  <p className="ach-earned" data-tip data-for="theCalm">The Calm</p>
                      :
                  <p className="ach-unearned" data-tip data-for="theCalm">The Calm</p>
                }
                <ReactTooltip id="theCalm"><span>Complete all intro challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2018 && this.state.commendations2018.includes(2)) ? 
                  <p className="ach-earned" data-tip data-for="upgrade">Upgrade!</p>
                      :
                  <p className="ach-unearned" data-tip data-for="upgrade">Upgrade!</p>
                }
                <ReactTooltip id="upgrade"><span>Complete all badge challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2018 && this.state.commendations2018.includes(3)) ? 
                  <p className="ach-earned" data-tip data-for="mapQuest">Map Quest</p>
                      :
                  <p className="ach-unearned" data-tip data-for="mapQuest">Map Quest</p>
                }
                <ReactTooltip id="mapQuest"><span>Complete all map challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2018 && this.state.commendations2018.includes(4)) ? 
                  <p className="ach-earned" data-tip data-for="cryptanalyst">Cryptanalyst</p>
                      :
                  <p className="ach-unearned" data-tip data-for="cryptanalyst">Cryptanalyst</p>
                }
                <ReactTooltip id="cryptanalyst"><span>Complete all cipher decryption challenges</span></ReactTooltip>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                {(this.state.commendations2018 && this.state.commendations2018.includes(5)) ? 
                  <p className="ach-earned" data-tip data-for="123456">123456</p>
                      :
                  <p className="ach-unearned" data-tip data-for="123456">123456</p>
                }
                <ReactTooltip id="123456"><span>Complete all password cracking challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2018 && this.state.commendations2018.includes(6)) ? 
                  <p className="ach-earned" data-tip data-for="layer8">Layer 8</p>
                      :
                  <p className="ach-unearned" data-tip data-for="layer8">Layer 8</p>
                }
                <ReactTooltip id="layer8"><span>Complete all packet sniffing challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2018 && this.state.commendations2018.includes(7)) ? 
                  <p className="ach-earned" data-tip data-for="steganographasaurus">Steganographasaurus</p>
                      :
                  <p className="ach-unearned" data-tip data-for="steganographasaurus">Steganographasaurus</p>
                }
                <ReactTooltip id="steganographasaurus"><span>Complete all steganography challenges</span></ReactTooltip>
              </Col>
              <Col xs={3}>
                {(this.state.commendations2018 && this.state.commendations2018.includes(8)) ? 
                  <p className="ach-earned" data-tip data-for="completionist">Completionist</p>
                      :
                  <p className="ach-unearned" data-tip data-for="completionist">Completionist</p>
                }
                <ReactTooltip id="completionist"><span>Complete all challenges</span></ReactTooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Status;
