import '@fortawesome/fontawesome-free/js/all';

import React, { Component } from 'react';
import { Route, Switch, Redirect}  from 'react-router-dom';
import './App.css';
import AppHeader from '../components/AppHeader';
import Auth from '../components/Auth';
import Authenticated from './Authenticated';
import Home from './Home';
import CTF from './CTF';
import Secure from './Secure';
import history from '../history';
import axios from '../utils/axios';
import config from '../utils/config.json';

function PrivateRoute({ comp: Comp, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        auth.isAuthenticated() === true
          ? <Comp {...props} {...rest} match={props.match} />
          : (
            <Redirect to={{
              pathname: '/',
              state: { from: props.location },
            }}
            />
          )
      )}
    />
  );
}

const auth = new Auth();

const getUnreadMessageStatus = (idToken,cb) => {
  const headers = { 'Authorization': `Bearer ${idToken}`}
  axios.get(`${config.apiUrl}/messages/check`, {headers})
  .then(res => {
    if (res.status === 200) {
    } else {
      console.log('Error verifying authentication token');
    }
    cb(res.data);
  })
  .catch(() => {
    console.log('Error sending request for unread messages');
    cb({success:false})
  })

}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      unreadMessage: false
    }
    this.updateUnreadMessage = this.updateUnreadMessage.bind(this);
  }

  updateUnreadMessage = (state) => {
    this.setState({
      unreadMessage:state
    })
  }

  componentDidMount() {
    if (this.props.location.pathname.toLowerCase() == '/bh2020') {
      localStorage.setItem('o', 'bh2020');
    }
    // check for unread messages
    if (auth.isAuthenticated()) {
      getUnreadMessageStatus(auth.getIdToken(), (result) => {
        if (result.success) {
          this.setState({unreadMessage:result.unreadMessage});
        }
      });
    }
  }

  render() {
    return (
      <div className="App">
	      <AppHeader auth={auth} history={history} unreadMessage={this.state.unreadMessage}/>
        <div className="App-Body">
          <Switch>
            <Route exact path="/" render={(props) => < Home auth={auth} updateUnreadMessage={this.updateUnreadMessage} {...props} />} />
            <Route exact path="/Authenticated" render={(props) => <Authenticated auth={auth} {...props} />} />
            <Route exact path="/bh2020" render={(props) => < Home auth={auth} updateUnreadMessage={this.updateUnreadMessage} {...props} />} />
            <PrivateRoute path="/CTF" comp={CTF} auth={auth} updateUnreadMessage={this.updateUnreadMessage} />
            <PrivateRoute path="/Secure" comp={Secure} auth={auth} />
          </Switch>
        </div>

      </div>
    );
  }
}

export default App;
