import React, { Component } from 'react';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { CancelToken } from 'axios';
import axios from '../../utils/axios';
import config from '../../utils/config.json';
import './ChallengeWindow.css';

let source;
function getChallenge(num) {
  return new Promise((resolve, reject) => {
    axios.get(`${config.apiUrl}/challenges/2022/${num}`,{cancelToken: source.token})
      .then(res => {
        if (res.status === 200 || res.status === 304) {
          return resolve(res.data);
        }
        return reject(new Error("Error finding challenge"));
      })
      .catch((error) => {
        return reject(error);
      })
  })
}

function checkAnswer(num, answer) {
  return new Promise((resolve, reject) => {
    var postData = {
      number: num,
      answer: answer
    }
    axios.post(
        `${config.apiUrl}/challenges/checkanswer/2022`,
        postData,
        {cancelToken: source.token}
      )
      .then(res => {
        if (res.status !== 200) {
          return reject(new Error("Error checking answer"));
        }
        return resolve(res.data);
      })
      .catch((error) => {
        return reject(error);
      })
  })
}

class ChallengeWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      title: 'Loading',
      description: 'Loading...',
      answer: '',
      completed: this.props.completed,
      incorrectText: ''
    };
    source = CancelToken.source();
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { num } = this.props;
    getChallenge(num)
      .then((result) => {
        this.setState({
          value:result.value,
          title:result.title,
          description:result.description        
        });
        if (result.newMessage) {
          localStorage.setItem("unreadMessage","true");
        }
      })
      .catch((error) => {
        if (error.message !== "Unmounted component"){
          console.log(error);
          this.setState({
            title: 'Error',
            description: error.message,
          })          
        }
      });
  }

  componentWillUnmount() {
    if (source) {
      source.cancel("Unmounted component");
    }
  }

  handleChange = (e) => {
    this.setState({ answer: e.target.value });
  }

  onSubmit = (e) => {
    var self = this;
    e.preventDefault();
    const { answer } = this.state;
    const {
      num,
      onClose,
      updateUnreadMessage,
    } = this.props;
    this.setState({
      incorrectText: '',
    });
    checkAnswer(num, answer)
      .then((result) => {
        if (result.success && result.correct) {
          this.setState({
            completed :true,
            incorrectText: '',
          });
          setTimeout( () => {
            onClose();
          }, 3000);
        } else if (result.success && !result.correct) {
          this.setState({
            incorrectText:result.message
          });
        }
        if (result.newMessage){
          updateUnreadMessage(true);
        }
      })
      .catch((error) => {
        if (error.message !== "Unmounted component"){
          console.log(error);
          this.setState({
            incorrectText:error.message
          })      
        }
      })
  }

  render() {
    const {
      answer,
      title,
      description,
      completed,
      incorrectText,
    } = this.state;
    const {
      onClose,
      num,
    } = this.props;
    return (
      <Container fluid className="challenge-box" id="ChallengeWindow">
        <Row>
          <Col xs={12} className="challenge-header">
            <Row>
              <Col xs={10} className="challenge-name">
                {title}
              </Col>
              <Col xs={2} className="challenge-controls" onClick={onClose}>
                <img className="close-button" alt='close button' src='https://wg-ctf.s3-us-west-1.amazonaws.com/images/close.png' />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="challenge-content">
            <div dangerouslySetInnerHTML={{__html:description}}></div>

            {completed && 
              <p className="victory-text">Well done!</p>}
            {incorrectText &&
              <p className="incorrect-text">{incorrectText}</p>}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="challenge-input">
            <Form>
              <Form.Group controlId="formInlineAnswer">
                <InputGroup>
                    <Form.Control type="text"
                    autoComplete="off"
                    value={answer} 
                    onChange={this.handleChange}
                    disabled={completed}
                    placeholder="Answer..."/>
                  <InputGroup.Prepend>
                    <Button onClick={this.onSubmit} type="submit" disabled={completed}>Submit</Button>
                  </InputGroup.Prepend>
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ChallengeWindow;
