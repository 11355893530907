import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import './CTF.css';
import Status from './Status';
import Challenges from './Challenges';
import Messages from './Messages';
import Leaderboard from './Leaderboard';
import Archive from './Archive';

class CTF extends Component {

  render() {
    return (
      <Switch>
        <Route exact path="/CTF/Status" render={(props) => <Status auth={this.props.auth} {...props} />} />
        <Route path="/CTF/Challenges" render={(props) => <Challenges auth={this.props.auth} updateUnreadMessage={this.props.updateUnreadMessage} {...props} />} />
        <Route path="/CTF/Messages" render={(props) => <Messages auth={this.props.auth} updateUnreadMessage={this.props.updateUnreadMessage} {...props} /> } />
        <Route path="/CTF/Leaderboard" render={(props) => <Leaderboard auth={this.props.auth} {...props} /> } />
        <Route path="/CTF/Archive" render={(props) => <Archive auth={this.props.auth} updateUnreadMessage={this.props.updateUnreadMessage} {...props} />} />
      </Switch>
    );
  }
}

export default CTF;
