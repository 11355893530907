import React from 'react';
import {Container, Row, Col } from 'react-bootstrap';

import './LoadingMessage.css';

function LoadingMessage() {

  return (
    <Container fluid className="LoadingMessage">
      <Row>
        <Col xs={12} md={{span: 10, offset: 1}}>
          <div className="announcement">
            <p>
              Loading...
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LoadingMessage;
