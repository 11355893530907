import React, { Component } from 'react';
//import { Navbar,Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavMenu from '../NavMenu';
import BurgerMenu from '../BurgerMenu';
import BurgerMenuButton from '../BurgerMenuButton';
import './AppHeader.css';

class NavHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerMenuDropdownOpened: false,
    };
    this.openBurgerMenuDropdown = this.openBurgerMenuDropdown.bind(this);
    this.closeBurgerMenuDropdown = this.closeBurgerMenuDropdown.bind(this);
  }

  // <<< Burger Menu Start >>>
  openBurgerMenuDropdown() {
    const { burgerMenuDropdownOpened } = this.state;
    this.setState({
      burgerMenuDropdownOpened: !burgerMenuDropdownOpened,
    });
  }

  closeBurgerMenuDropdown() {
    this.setState({
      burgerMenuDropdownOpened: false,
    });
  }

  goTo(route) {
    this.props.history.replace(`${route}`)
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  openDiscord() {
    window.open('https://discord.gg/ByWzR2N','_blank');
  }

  render() {
    const {
      auth,
      history,
    } = this.props;
    const { burgerMenuDropdownOpened } = this.state;
    return (
      <div id="AppHeader">
        <BurgerMenuButton
          auth={auth}
          openBurgerMenuDropdown={this.openBurgerMenuDropdown}
        />
        <NavMenu auth={auth} />
        {burgerMenuDropdownOpened
          ? (
            <BurgerMenu
              auth={auth}
              history={history}
              handleClose={this.closeBurgerMenuDropdown}
            />
          )
          : ''}
      </div>
    )
  }
}

export default NavHeader
