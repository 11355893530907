import React from 'react';
import './LoadingSpinner.css';

function LoadingSpinner() {
  return (
    <div className="loading-spinner">
      <i className="fas fa-spinner fa-spin loading-spinner-icon" />
    </div>
  )
}

export default LoadingSpinner;