const StorageService = (() => {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setTokens(accessToken, idToken, expires) {
    const expiresAt = JSON.stringify((expires * 1000) + new Date().getTime());
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  function _destroyTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
  }

  function _getAccessToken() {
    return localStorage.getItem('access_token');
  }

  function _getTokenExpiration() {
    return localStorage.getItem('expires_at');
  }

  function _getIdToken() {
    return localStorage.getItem('id_token');
  }

  return {
    getService: _getService,
    setTokens: _setTokens,
    getAccessToken: _getAccessToken,
    getIdToken: _getIdToken,
    getTokenExpiration: _getTokenExpiration,
    destroyTokens: _destroyTokens,
  };
})();
export default StorageService;
