import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {CancelToken} from 'axios';
import ArchiveChallengeWindow from '../../../components/ArchiveChallengeWindow';
import ChallengeBox from '../../../components/ChallengeBox';
import axios from '../../../utils/axios';
import config from '../../../utils/config.json';
import './Archive.css';

let source;

function getArchiveChallenges(year) {
  return new Promise((resolve, reject) => {
    axios.get(`${config.apiUrl}/challenges/${year}`,{cancelToken: source.token})
      .then(res => {
        if (res.status !== 200) {
          return reject(new Error("No challenges found"));
        }
        return resolve(res.data);
      })
      .catch((error) => {
        return reject(error);
      })
  })
}

class Archive extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedNum: '',
      selectedYear: '',
      challenges2018: [],
      challenges2019: [],
      challenges2020: [],
      challenges2021: [],
    }
    source = CancelToken.source();
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    var self = this;
    getArchiveChallenges(2018)
      .then((result) => {
        this.setState({
          challenges2018: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);          
        }
      });
    getArchiveChallenges(2019)
      .then((result) => {
        this.setState({
          challenges2019: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);          
        }
      });
    getArchiveChallenges(2020)
      .then((result) => {
        this.setState({
          challenges2020: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);          
        }
      });
      getArchiveChallenges(2021)
      .then((result) => {
        this.setState({
          challenges2021: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);          
        }
      });
  }

  componentWillUnmount() {
    if (source) {
      source.cancel("Unmounted component");
    }
  }

  handleClick = (num,year,completed) => {
    this.setState({
      selectedNum: num,
      selectedYear: year,
      completed: completed
    });
  };

  handleClose = () => {
    var self = this;
    getArchiveChallenges(2018)
      .then((result) => {
        this.setState({
          selectedNum: '',
          selectedYear: '',
          completed: false,
          challenges2018: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);          
        }
      });
    getArchiveChallenges(2019)
      .then((result) => {
        this.setState({
          selectedNum: '',
          selectedYear: '',
          completed: false,
          challenges2019: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);          
        }
      });
    getArchiveChallenges(2020)
      .then((result) => {
        this.setState({
          selectedNum: '',
          selectedYear: '',
          completed: false,
          challenges2020: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);          
        }
      });
    getArchiveChallenges(2021)
      .then((result) => {
        this.setState({
          selectedNum: '',
          selectedYear: '',
          completed: false,
          challenges2021: result.challenges,
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);          
        }
      });
  };

  render() {
    const {
      challenges2018,
      challenges2019,
      challenges2020,
      challenges2021,
    } = this.state;

    return (
      <Container fluid className="archive-challenges">
      {(this.state.selectedNum) ? (
        <ArchiveChallengeWindow
          auth={this.props.auth}
          updateUnreadMessage={this.props.updateUnreadMessage}
          onClose={this.handleClose}
          num={this.state.selectedNum}
          year={this.state.selectedYear} 
          completed={this.state.completed} />
      ) : (
        <Col xs={12}>
          <Row>
            <Col xs={{span: 2, offset: 5}} className="placeholder archiveYear">
              2021
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
            {challenges2021 && challenges2021.map((challenge, index, a) => (
              <Row key={challenge.number}>
              <Col xs={4}>
                <ChallengeBox 
                  title={challenge.title}
                  num={challenge.number}
                  year={2021}
                  value={challenge.value} 
                  completed={challenge.completed}
                  onClick={this.handleClick}/>
              </Col>
              </Row>
            ))}
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 2, offset: 5}} className="placeholder archiveYear">
              2020
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
            {challenges2020 && challenges2020.map((challenge, index, a) => (
              <Row key={challenge.number}>
              <Col xs={4}>
                <ChallengeBox 
                  title={challenge.title}
                  num={challenge.number}
                  year={2020}
                  value={challenge.value} 
                  completed={challenge.completed}
                  onClick={this.handleClick}/>
              </Col>
              </Row>
            ))}
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 2, offset: 5}} className="placeholder archiveYear">
              2019
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
            {challenges2019 && challenges2019.map((challenge, index, a) => (
              <Row key={challenge.number}>
              <Col xs={4}>
                <ChallengeBox 
                  title={challenge.title}
                  num={challenge.number}
                  year={2019}
                  value={challenge.value} 
                  completed={challenge.completed}
                  onClick={this.handleClick}/>
              </Col>
              </Row>
            ))}
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 2, offset: 5}} className="placeholder archiveYear">
              2018
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
            {challenges2018 && challenges2018.map((challenge, index, a) => (
              <Row key={challenge.number}>
              <Col xs={4}>
                <ChallengeBox 
                  title={challenge.title}
                  num={challenge.number}
                  year={2018}
                  value={challenge.value} 
                  completed={challenge.completed}
                  onClick={this.handleClick}/>
              </Col>
              </Row>
            ))}
            </Col>
          </Row>
        </Col>
      )}
      </Container>
    );
  }
}

export default Archive;
