import './BurgerMenu.css';

import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import {useRef, useEffect } from 'react';

export default function BurgerMenu(props) {
  const ref = useRef(null);

  const { handleClose } = props;
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  /*
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickLogin = this.handleClickLogin.bind(this);
    this.handleClickLogout = this.handleClickLogout.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  }*/

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target))
      {
        handleClose && handleClose();
      }
    };


    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ handleClose ]);

  return (
    isAuthenticated
      ? (
        <Container id="BurgerMenu" ref={ref}>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth browse">
              <Link to="/Home" onClick={handleClose}>
                <div><i className="fas fa-search burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Home</div>
              </Link>
            </Col>
          </Row>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth app">
              <Link to="/CTF/Challenges" onClick={handleClose}>
              <div><i className="fas fa-cubes burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Challenges</div>
              </Link>
            </Col>
          </Row>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth clusters">
              <Link to="/CTF/Leaderboard" onClick={handleClose}>
                <div><i className="fas fa-cubes burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Leaderboard</div>
              </Link>
            </Col>
          </Row>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth clusters">
              <Link to="/CTF/Messages" onClick={handleClose}>
                <div><i className="fas fa-cubes burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Messages</div>
              </Link>
            </Col>
          </Row>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth clusters">
              <Link to="/CTF/Archive" onClick={handleClose}>
                <div><i className="fas fa-cubes burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Archive</div>
              </Link>
            </Col>
          </Row>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth clusters">
              <Link to="/CTF/Status" onClick={handleClose}>
                <div><i className="fas fa-cubes burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Profile</div>
              </Link>
            </Col>
          </Row>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth clusters">
              <Link to="/Logout" onClick={() => logout()}>
                <div><i className="fas fa-cubes burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Logout</div>
              </Link>
            </Col>
          </Row>
        </Container>
      )
      : (
        <Container id="BurgerMenu" ref={ref}>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth browse">
              <Link to="/Home" onClick={handleClose}>
                <div><i className="fas fa-search burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Home</div>
              </Link>
            </Col>
          </Row>
          <Row className="burger-menu-item">
            <Col xs={12} id="burger-menu-login" className="burger-menu-auth clusters">
              <Link to="/Login" onClick={() => loginWithRedirect()}>
                <div><i className="fas fa-cubes burger-menu-item-icon" /></div>
                <div className="burger-menu-item-label">Login</div>
              </Link>
            </Col>
          </Row>
        </Container>
      )
  );
}
