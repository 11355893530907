import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {CancelToken} from 'axios';
import LoadingSpinner from '../../../components/LoadingSpinner';
import MessageWindow from '../../../components/MessageWindow';
import axios from '../../../utils/axios';
import config from '../../../utils/config.json';
import './Messages.css';

let source;

function getMessages() {
  return new Promise((resolve, reject) => {
    axios.get(`${config.apiUrl}/messages`, {cancelToken: source.token})
      .then(res => {
        if (res.status === 200 || res.status === 304) {
          return resolve(res.data);
        }
        return reject(new Error("Error finding messages"));
      })
      .catch(error => {
        return reject(error);
      })
  })
}

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: '',
      loading: true,
      messages: []
    };
    source = CancelToken.source();
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  componentDidMount() {
    getMessages()
      .then((result) => {
        this.setState({
          loading: false,
          selected: '',
          messages:result.messages
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted component"){
          console.log(error);
          this.setState({
            loading: false,
          });     
        }
      });    
  }

  componentWillUnmount() {
    if (source) {
      source.cancel("Unmounted component");
    }
  }


  handleClick = (msgid) => {
    this.setState({
      selected: msgid
    });
  };

  handleClose = () => {
    this.setState({
      loading: true,
    });
    getMessages()
      .then((result) => {
        this.setState({
          loading: false,
          selected: '',
          messages:result.messages
        });
      })
      .catch((error) => {
        if (error.message !== "Unmounted component"){
          console.log(error);
          this.setState({
            loading: false,
          });     
        }
      });   
  };

  render() {
    const { loading, messages, selected } = this.state;
    const { auth } = this.props;
    return (
      <Container fluid className="messages">
      {loading
       ? (
         <LoadingSpinner />
       )
      : ''}
      {selected ? (
        <MessageWindow
        auth={auth}
        onClose={this.handleClose}
        msgid={selected} />
      ) : (
        <Row>
          {!loading && messages && messages.length > 0
            ? (
              <Col xs={{span: 10, offset:1}}>
                {messages && messages.map((message, index, a) => (
                  <Row key={message._id} msgid={message._id} className={message.read ? (
                        "message read"
                      ):"message unread"}>
                    <Col xs={6} className={"message-subject"}
                      msgid={message._id}
                      onClick={(e) => this.handleClick(message._id,e)}>{message.subject} | From: {message.from}</Col>
                  </Row>
                ))}
              </Col>
            )
            : ''}
          {!loading && messages && messages.length === 0
            ? (
              <Col xs={{span: 10, offset:1}}>
                <div className="no-messages">You have no messages</div>
              </Col>
            )
            : ''}
        </Row>
      )}
      </Container>
    );
  }
}

export default Messages;
