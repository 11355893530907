import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import './index.css';
import App from './containers/App';
import config from './utils/config.json';
//import registerServiceWorker from './registerServiceWorker';
import * as serviceWorker from './registerServiceWorker';
import history from './history';


ReactDOM.render((
  
    <BrowserRouter history={history}>
      <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={window.location.origin+'/authenticated'}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      >
      <Route path = '/' component={ App } />
      </Auth0Provider>
    </BrowserRouter>),
  document.getElementById('root'),
);
//registerServiceWorker();
serviceWorker.unregister();