import React, { Component } from 'react';
import {Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import './Home.css';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seconds: 0,
      apiKey: '',
      keySaved: false,
    };
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
    this.changeAPIKey = this.changeAPIKey.bind(this);
    this.submitAPIKey = this.submitAPIKey.bind(this);
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    const c = new Date("Jul 31, 2021 15:00:00 GMT+00:00").getTime();
    const n = new Date().getTime();
    let d = c - n;
    if (d < 0) { d = 0 };

    this.setState({ seconds: Math.floor(d/1000) });

    // Check if we're at zero.
    if (d <= 0) { 
      clearInterval(this.timer);
    }
  }

  componentDidMount() {
    const apiKey = localStorage.getItem('api_key') || '';
    const c = new Date("Jul 31, 2021 15:00:00 GMT+00:00").getTime();
    const n = new Date().getTime();
    let d = c - n;
    if (d < 0) { d = 0 };
    this.setState({
      seconds: Math.floor(d/1000),
      apiKey,
    });
    if (d > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  changeAPIKey(e) {
    this.setState({apiKey: e.target.value});
  }

  submitAPIKey(e) {
    const { apiKey } = this.state;
    e.preventDefault;
    localStorage.setItem('api_key', apiKey);
  }

  goTo(route) {
    this.props.history.replace(`${route}`)
  }

  login(e) {
    e.preventDefault();
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  render() {
    const { apiKey } = this.state;
    return (
      <Container fluid className="Home">
        <Row>
          <Col xs={12} md={{span: 10, offset: 1}}>
            <div className="announcement">
              <p className="glow">
                1660150800
              </p>
              <Form>
                <InputGroup>
                  <Form.Control type="text"
                    autoComplete="off"
                    value={apiKey} 
                    onChange={this.changeAPIKey}
                    placeholder="APIKey"/>
                </InputGroup>
                <InputGroup.Prepend>
                <Button onClick={this.submitAPIKey} type="submit">Submit</Button>
              </InputGroup.Prepend>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Home;
