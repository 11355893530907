import './NavMenu.css';

import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

function openDiscord() {
  window.open('https://discord.gg/ByWzR2N','_blank');
}

function NavMenu(props) {

  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <div id="NavMenu">
      { isAuthenticated
        ? (
          <div className="nav-menu-bar">
            <Link className="nav-link" to="/">Home</Link>
            <Link className="nav-link" to="/CTF/Challenges">Challenges</Link>
            <Link className="nav-link" to="/CTF/Leaderboard">Leaderboard</Link>
            <Link className="nav-link" to="/CTF/Messages">Messages</Link>
            <div className="right-nav-links">
              <Link className="nav-link" to="/CTF/Archive">Archive</Link>
              <div className="nav-link discord-link" onClick={openDiscord}>
                Discord
              </div>
              <Link className="nav-link" to="/CTF/status">Profile</Link>
              <div className="nav-link" onClick={() => logout()}>
                Log Out
              </div>
            </div>
          </div>
        )
        : (
          <div className="nav-menu-bar">
            <Link className="nav-link" to="/">Home</Link>
            <div className="right-nav-links">
              <div className="nav-link" onClick={() => loginWithRedirect()}>
                Log In
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
export default NavMenu;
