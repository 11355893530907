import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './ChallengeBox.css';


class ChallengeBox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      title: this.props.title,
      completed: this.props.completed
    };
  }

  handleClick = () => {
    this.props.onClick(this.props.num,this.props.year,this.props.completed);
  }

  render() {
    return (
      <div onClick={this.handleClick}
        num={this.props.num}
        year={this.props.year}
        className={this.props.completed ?
                   "challenge-bubble completed" :
                   "challenge-bubble"}>
        <Row>
          <Col xs={8} className="challenge-bubble-title">
            {this.props.title}
          </Col>
          <Col xs={4} className="challenge-bubble-value">
            Points: {this.props.value}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ChallengeBox;
