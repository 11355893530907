import axios from 'axios';
import StorageService from './StorageService';
import appConfig from './config.json';


const storageService = StorageService.getService();
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async config => {
    const token = storageService.getIdToken();
    //const token = await getIdTokenClaims();
    const apiKey = localStorage.getItem('api_key');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.log('no token');
    }
    if (apiKey) {
      config.headers['X-Api-Key'] = `${apiKey}`;
    }
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default axiosInstance;