import React, { Component } from 'react';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import axios from '../../utils/axios';
import config from '../../utils/config.json';
import './Secure.css';

const checkCreds = (username, password, idToken, cb) => {
    var postData = {
      username: username,
      password: password
    }
    axios.post(`${config.apiUrl}/checkcreds`,postData)
    .then(res => {
      if (res.status === 200) {
      } else {
        console.log('Error checking answer');
      }
      cb(res.data);
    })
    .catch(() => {
      console.log('Error checking answer');
      cb({success:false})
    })
  }

class Secure extends Component {
    constructor(props) {
        super(props);
    
        this.state = {

          username: '',
          password: '',
          message: ''
        };
    
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  handleChangeUsername = (e) => {
    this.setState({ username: e.target.value });
  }

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value });
  }

  onSubmit = (e) => {
    var self = this;
    e.preventDefault();
    checkCreds(this.state.username,this.state.password,this.props.auth.getIdToken(), (result) => {
      if (result.success && result.flag) {
        self.setState({
          username: '',
          password: '',
          message:result.flag
        });
      } else {
        self.setState({
          message:result.message
        });
      }
      setTimeout( () => {
        self.setState({
            message:''
          });
      }, 15000);
    });
  }

  render() {
    const {
      message,
      password,
      username,
    } = this.state;

    return (
        <Container fluid id="Secure">
            <Form>
                <Form.Group controlId="formInlineCred">
                    <Col xs={{span: 4, offset:4}}>
                    <Row>
                        <Col xs={12} className="secure-username">
                            <InputGroup className="secure-input">
                                <Form.Control type="text"
                                autoComplete="off"
                                value={username} 
                                onChange={this.handleChangeUsername}
                                placeholder="Userrname..."/>
                            </InputGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col xs={12} className="secure-password">
                            <InputGroup className="secure-input">
                                <Form.Control type="text"
                                autoComplete="off"
                                value={password} 
                                onChange={this.handleChangePassword}
                                placeholder="Password..."/>
                            </InputGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col xs={12} className="secure-submit">
                            <InputGroup.Prepend>
                                <Button onClick={this.onSubmit} type="submit">Submit</Button>
                            </InputGroup.Prepend>
                        </Col>
                        </Row>
                        <Row>
                        <Col xs={12} className={message !== "" ? "secure-message" : "secure-message disabled"}>
                            <div className="message">{message}</div>
                        </Col>
                    </Row>
                    </Col>
                </Form.Group>
            </Form>
      </Container>
    );
  }
}

export default Secure;
