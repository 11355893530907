import './Leaderboard.css';

import React, { Component } from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {CancelToken} from 'axios';
import axios from '../../../utils/axios';
import config from '../../../utils/config.json';

let source;

var posIndex = 0;

function onPageChange(newPage, sizePerPage) {
  posIndex = (newPage*sizePerPage)-sizePerPage;
}

function indexN(cell, row, enumObject, index) {
  return(<div>{index+posIndex+1}</div>);
}

function getLeaderboard() {
  return new Promise((resolve, reject) => {
    axios.get(`${config.apiUrl}/leaderboard`,{cancelToken: source.token})
      .then(res => {
        if (res.status !== 200) {
          return reject(new Error('Error pulling leaderboard'));
        }
        return resolve(res.data);
      })
      .catch((error) => {
        return reject(error);
      })
  })
}

class Leaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaderboard: [],
      mgPoints: 0,
      wrPoints: 0,
      loading: true,
    };
    source = CancelToken.source();
  }

  componentDidMount() {
    getLeaderboard()
      .then((results) => {
        this.setState({
          leaderboard:results.leaderboard,
          loading: false,
        })
      })
      .catch((error) => {
        if (error.message !== "Unmounted leaderboard"){
          console.log(error);
          this.setState({
            loading: false,
          });
        }
      })
  }

  componentWillUnmount() {
    if (source) {
      source.cancel("Unmounted leaderboard");
    }
  }

  placeholderFormatter(cell, row) {
    return `<p>[Classified]</p>`
  }

  render() {
    const {
      leaderboard,
      loading,
    } = this.state;

    const tableOptions = {
      sizePerPage:25,
      onPageChange: onPageChange
    }

    const NoTableData = () => {
      if (loading) {
        return(<p>Loading...</p>);
      }
      return(<p>Leaderboard Failed To Load!</p>);
    }


    return (
      <Container fluid className="leaderboard" id="Leaderboard">
        <Row>
          <Col xs={{span: 10, offset: 1}}> 
          <BootstrapTable className="leaderboard-table"
                bordered={ false}
                data={leaderboard}
                options={tableOptions} 
                tableHeaderClass='leaderboard-header'
                noDataIndication={NoTableData}
                pagination>
            <TableHeaderColumn dataField="username" dataFormat={indexN}>#</TableHeaderColumn>
            <TableHeaderColumn dataField="username" isKey>Username</TableHeaderColumn>
            <TableHeaderColumn dataField="points" >Points</TableHeaderColumn>
          </BootstrapTable>
          </Col>
        </Row>
      </Container>

    );
  }
}

export default Leaderboard;
