import React, { Component } from 'react';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import axios from '../../utils/axios';
import config from '../../utils/config.json';
import './ArchiveChallengeWindow.css';

const getChallenge = (num,year,idToken,cb) => {
  axios.get(`${config.apiUrl}/challenges/${year}/${num}`)
  .then(res => {
    if (res.status === 200 || res.status === 304) {
      cb(res.data);
    } else {
      console.log('Error searching for challenge');
      cb({success:false});
    }
  })
  .catch(() => {
    console.log('Error sending challenge request');
    cb({success:false})
  })
}

const checkAnswer = (num,year, answer, idToken, cb) => {
  var postData = {
    number: num,
    answer: answer
  }
  axios.post(`${config.apiUrl}/challenges/checkanswer/${year}`,postData)
  .then(res => {
    if (res.status === 200) {
    } else {
      console.log('Error checking answer');
    }
    cb(res.data);
  })
  .catch(() => {
    console.log('Error checking answer');
    cb({success:false})
  })
}

class ArchiveChallengeWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      title: 'unknown',
      description: 'unknown',
      answer: '',
      completed: this.props.completed,
      incorrectText: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var self = this;
    getChallenge(this.props.num,this.props.year,this.props.auth.getIdToken(), (result) => {
      if (result.success) {
        self.setState({
          value:result.value,
          title:result.title,
          description:result.description
        });
      }
      if(result.newMessage) {
        localStorage.setItem("unreadMessage","true");
      }
    });
  }

  handleChange = (e) => {
    this.setState({ answer: e.target.value });
  }

  onSubmit = (e) => {
    var self = this;
    e.preventDefault();
    var answer = this.state.answer;
    /*
    if (this.props.year === 2019 && this.props.num === 10042) {
      answer = answer.replace("-----BEGIN PGP MESSAGE-----","");
      answer = answer.replace("-----END PGP MESSAGE-----","");
      answer
      answer = answer.split(" ").join("\n");
      answer = "-----BEGIN PGP MESSAGE-----\n" + answer + "-----END PGP MESSAGE-----\n";
    }*/
    checkAnswer(this.props.num,this.props.year,answer,this.props.auth.getIdToken(), (result) => {
      if (result.success && result.correct) {
        self.setState({
          completed:true,
          incorrectText:''
        });
        setTimeout( () => {
          this.props.onClose();
        }, 3000);
      } else if (!result.correct) {
        self.setState({
          incorrectText:self.state.incorrectText+"incorrect "
        })
      }
      if (result.newMessage){
        this.props.updateUnreadMessage(true);
      }
    });
  }

  render() {
    return (
      <Container fluid className="challenge-box" id="ArchiveChallengeWindow">
        <Row>
          <Col xs={12} className="challenge-header">
            <Row>
              <Col xs={10} className="challenge-name">
                {this.state.title}
              </Col>
              <Col xs={2} className="challenge-controls" onClick={this.props.onClose}>
                <img className="close-button" alt='close button' src='https://wg-ctf.s3-us-west-1.amazonaws.com/images/close.png' />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="challenge-content">
            <div dangerouslySetInnerHTML={{__html:this.state.description}}></div>

            {this.state.completed && 
              <p className="victory-text">Well done!</p>}
            {this.state.incorrectText &&
              <p className="incorrect-text">{this.state.incorrectText}</p>}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="challenge-input">
            <Form>
              <Form.Group controlId="formInlineAnswer">
                <InputGroup>
                  {(this.props.num === 10042 && this.props.year === 2019) ?
                    <Form.Control as="textarea"
                    autoComplete="off"
                    value={this.state.answer} 
                    onChange={this.handleChange}
                    disabled={this.state.completed && this.props.num !== 20002}
                    placeholder="Answer..."/>  
                  :
                    <Form.Control type="text"
                    autoComplete="off"
                    value={this.state.answer} 
                    onChange={this.handleChange}
                    disabled={this.state.completed && this.props.num !== 20002}
                    placeholder="Answer..."/>
                  }
                  <InputGroup.Prepend>
                    <Button onClick={this.onSubmit} type="submit" disabled={this.state.completed && this.props.num !== 20002}>Submit</Button>
                  </InputGroup.Prepend>
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ArchiveChallengeWindow;
