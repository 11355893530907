import React, { Component } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import axios from '../../utils/axios';
import config from '../../utils/config.json';
import './MessageWindow.css';

const getMessage = (msgid,idToken,cb) => {
  axios.get(`${config.apiUrl}/messages/${msgid}`)
  .then(res => {
    if (res.status === 200) {
    } else {
      console.log('Error searching for message');
    }
    cb(res.data);
  })
  .catch(() => {
    console.log('Error sending message request');
    cb({success:'false'})
  })
}


class MessageWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      from: 'Loading',
      sent: 'Loading',
      subject: 'Loading',
      body: 'Loading',
    };

  }

  componentDidMount() {
    var self = this;
    getMessage(this.props.msgid,this.props.auth.getIdToken(), (result) => {
      if (result.success) {
        self.setState({
          from:result.from,
          sent:result.sent,
          subject:result.subject,
          body:result.body
        });
      }
    });
  }

  render() {
    return (
      <Container fluid className="message-box">
        <Row>
          <Col xs={12} className="message-header">
            <Row>
              <Col xs={10} className="message-subject">
                {this.state.subject} | From: {this.state.from}
              </Col>
              <Col xs={2} className="message-controls" onClick={this.props.onClose}>
                <img className="close-button" alt='close button' src='https://wg-ctf.s3-us-west-1.amazonaws.com/images/close.png' />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="message-content">
            <div dangerouslySetInnerHTML={{__html:this.state.body}}></div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MessageWindow;