import { useAuth0 } from "@auth0/auth0-react";


import history from '../../history';
import axios from '../../utils/axios';
import config from '../../utils/config.json';
import StorageService from '../../utils/StorageService';

const storageService = StorageService.getService();

export default class Auth {


  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
  }

  userProfile;

  login() {
    const { loginWithRedirect } = useAuth0();
    this.lock.show();
  }

  setSession() {

    //storageService.setTokens(authResult.accessToken, authResult.idToken, authResult.expiresIn);
    axios.get(`${config.apiUrl}/authenticate`)
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          history.push('/');
          window.location.reload(false);

        } else {
          console.log('Error verifying authentication token');
          storageService.destroyTokens();
          history.push('/');
          window.location.reload(false);
        }
      })
      .catch(() => {
        console.log('Error sending authentication request');
        storageService.destroyTokens();
        history.push('/');
        window.location.reload(false);
      })
  }

  logout() {
    storageService.destroyTokens();
    history.push('/');
    window.location.reload(false);
  }

  isAuthenticated() {
    const expiresAt = JSON.parse(storageService.getTokenExpiration());
    return expiresAt && new Date().getTime() < expiresAt;
  }

  getAccessToken() {
    return storageService.getAccessToken();
  }

  getIdToken() {
    return storageService.getIdToken();
  }

  getProfile(cb) {
    const accessToken = storageService.getAccessToken();
    this.lock.getUserInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }
  
}
