import React from 'react';
import {Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import history from '../../history';
import axios from '../../utils/axios';
import config from '../../utils/config.json';
import StorageService from '../../utils/StorageService';
import {  useAuth0 } from "@auth0/auth0-react";

import './Authenticated.css';

const storageService = StorageService.getService();

function Authenticated(props) {


  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading } = useAuth0();
  
  if (isAuthenticated)
  {
    getAccessTokenSilently()
    .then((accessToken) => {
      console.log(accessToken);
      return getIdTokenClaims()
        .then((claims) => {
          return storageService.setTokens(accessToken, claims.__raw, claims.exp);
        })
        .then(() => axios.get(`${config.apiUrl}/authenticate`))
        .then(res => {
          if (res.status === 200) {
            history.push('/CTF/Challenges');
            window.location.reload(false);
    
          } else {
            console.log('Error verifying authentication token');
            StorageService.destroyTokens();
            history.push('/');
            window.location.reload(false);
          }
        })
        .catch((err) => {
          console.log('Error sending authentication request');
          console.log(err);
          StorageService.destroyTokens();
          history.push('/');
           window.location.reload(false);
        })
    })
    .catch((error) => {
      console.log('Error sending authentication request');
      console.log(error);
      StorageService.destroyTokens();
      history.push('/');
    })
  }

  return (
    <Container fluid className="Authenticated">
      <Row>
        <Col xs={12} md={{span: 10, offset: 1}}>
          <div className="announcement">
            <p>
              Redirecting...
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Authenticated;
